/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { LinearProgress, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { addToast as addToastAction } from "../../actions/toasts";

import { indexLineups as indexLineupsAction } from "../../actions/lineups";
import {
  updateQualification as updateQualificationAction,
  deleteQualification as deleteQualificationAction
} from "../../actions/qualifications";
import {
  createTechChannel as createTechChannelAction,
  updateTechChannel as updateTechChannelAction,
  deleteTechChannel as deleteTechChannelAction
} from "../../actions/tech_channels";
import {
  updateTransponder as updateTransponderAction,
  deleteTransponder as deleteTransponderAction
} from "../../actions/transponders";
import {
  indexServices as indexServicesAction,
  deleteService as deleteServiceAction
} from "../../actions/services";
import {
  indexEditorialChannels as indexEditorialChannelsAction,
  updateEditorialChannel as updateEditorialChannelAction
} from "../../actions/editorial_channels";

import {
  listQualifications as listQualificationsSelector
} from "../../selectors/qualifications";
import { listOrbitalPositionsLineupsFromBeamId as listOrbitalPositionsLineupsFromBeamIdSelector } from "../../selectors/lineups";
import { listOrbitalPositions as listOrbitalPositionsSelector } from "../../selectors/orbital_positions";
import { listServices as listServicesSelector } from "../../selectors/services";
import { listTransponders as listTranspondersSelector } from "../../selectors/transponders";
import { listTechChannels as listTechChannelsSelector } from "../../selectors/tech_channels";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import {
  qualificationServiceName
} from "../../helpers/utils";

import FullLoader from "../FullLoader";
import Infos from "./Infos";

import "./QualificationModal.css";

const initialState = {
  step: 0,
  maxStep: 0,
  currentAction: "",

  // TechChannel
  // Create Action
  lineupId: 0,
  orbitalPositionId: 0,
  predefinedTechChannels: [],

  // Delete Action
  deleteEditorialChannelIfPossible: false,

  hasFetchedLineups: false,
  hasFetchedServices: false,
  hasFetchedEditorialChannels: false,

  modalOpened: false
};

class QualificationModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      maxStep: props.currentQualificationsIds.length - 1
    };

    this.changeInput = this.changeInput.bind(this);
    this.closeLineupServiceModal = this.closeLineupServiceModal.bind(this);
  }

  async componentDidMount() {
    const { hasFetchedLineups, hasFetchedServices, hasFetchedEditorialChannels } = this.state;
    const { indexLineups, indexServices, indexEditorialChannels } = this.props;

    if (!hasFetchedServices) {
      this.setState({ hasFetchedServices: true });

      await indexServices({}, true);
    }

    if (!hasFetchedLineups) {
      this.setState({ hasFetchedLineups: true });

      await indexLineups({}, true);
    }

    if (!hasFetchedEditorialChannels) {
      this.setState({ hasFetchedEditorialChannels: true });

      await indexEditorialChannels({}, true);
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      currentQualificationsIds
    } = this.props;

    if (currentQualificationsIds.length !== prevProps.currentQualificationsIds.length) {
      this.setState({
        step: 0,
        maxStep: currentQualificationsIds.length - 1
      });
    }
  }

  changeInput(inputName, event) {
    let { value } = event.target;

    if (inputName === "lineupId") {
      value = parseInt(value, 10);
    }

    this.setState(() => ({ [inputName]: value }), true);
  }

  toggleInput(inputName) {
    const { [inputName]: inputValue } = this.state;

    this.setState({ [inputName]: !inputValue });
  }

  async nextStep() {
    const {
      step,
      maxStep
    } = this.state;

    const {
      addToast
    } = this.props;

    if (step < maxStep) {
      this.setState({
        step: step + 1,
        currentAction: "",
        lineupId: 0,
        orbitalPositionId: 0,
        predefinedTechChannels: []
      });
    } else {
      addToast("success", "Success", "Notifications have been processed!");
      this.closeModal(true);
      this.setState(initialState);
    }
  }

  async processQualification(action, deleteEditorial) {
    const {
      step,
    } = this.state;

    const {
      currentQualificationsIds,

      qualifications,
      services,
      techChannels,
      transponders,

      updateQualification,
      deleteQualification,
      deleteService,
      createTechChannel,
      updateTechChannel,
      deleteTechChannel,
      updateEditorialChannel,
      updateTransponder,
      deleteTransponder,
      addToast
    } = this.props;

    let goNext = true;

    if (step < currentQualificationsIds.length) {
      const currentQualification = qualifications.find(q => q.id === currentQualificationsIds[step]);

      // process actions
      /* TECH CHANNEL */
      if (currentQualification.data_type === "TechChannel") {
        if (currentQualification.qualification_type === "Creation") {
          if (Array.isArray(currentQualification.data.service_name)) {
            currentQualification.data.service_name = currentQualification.data.service_name[0];
            currentQualification.data.onid = currentQualification.data.onid[0];
            currentQualification.data.sid = currentQualification.data.sid[0];
            currentQualification.data.tsid = currentQualification.data.tsid[0];
          }
          if (action === "create" || action === "createtotransfer") {
            // Create
            const transponder = transponders.find(t => t.id === currentQualification.data.transponder_id);

            if (transponder) {
              const techChannelParam = {
                transponder_id: currentQualification.data.transponder_id,
                service_name: currentQualification.data.service_name || qualificationServiceName(transponder, currentQualification.data),
                tech_channel_type: "dvb",
                scrambled: currentQualification.data.scrambled,
                onid: transponder.theoretical_onid,
                tsid: transponder.theoretical_tsid,
                sid: currentQualification.data.sid,
                status: action === "createtotransfer" ? "transfer" : "pending"
              };

              try {
                const { isSuccess } = await createTechChannel(techChannelParam);

                if (!isSuccess) {
                  throw new Error();
                }

                await deleteQualification(currentQualification.id);

                if (action === "createtotransfer") {
                  addToast("success", "Success", "Technical channel has been successfully created in the \"To transfer\" status");
                } else {
                  addToast("success", "Success", "Technical channel has been successfully created and put in the pending list");
                }
              } catch (error) {
                addToast("error", "Error", "Error while creating the technical channel. Try again later");

                goNext = false;
              }
            } else {
              addToast("error", "Error", "The targeted transponder cannot be identified. Try again later");

              goNext = false;
            }
          } else if (action === "transferlist") {
            try {
              await updateQualification(currentQualification.id, { transferlist: true });

              addToast("success", "Success", "Notification was successfully put in the transfer list");
            } catch (error) {
              addToast("error", "Error", "Error while moving the notification. Try again later");

              goNext = false;
            }
          } else if (action === "dismissqualif") {
            // dismiss notification
            // Create TC in the discarded list
            // Delete qualif
            const transponder = transponders.find(t => t.id === currentQualification.data.transponder_id);

            if (transponder) {
              const techChannelParam = {
                transponder_id: currentQualification.data.transponder_id,
                service_name: currentQualification.data.service_name || qualificationServiceName(transponder, currentQualification.data),
                tech_channel_type: "dvb",
                scrambled: currentQualification.data.scrambled,
                onid: transponder.theoretical_onid,
                tsid: transponder.theoretical_tsid,
                sid: currentQualification.data.sid,
                status: "discarded"
              };

              try {
                const { isSuccess } = await createTechChannel(techChannelParam);

                if (!isSuccess) {
                  throw new Error();
                }

                await deleteQualification(currentQualification.id);

                addToast("success", "Success", "Notification has been successfully dismissed");
              } catch (error) {
                addToast("error", "Error", "Error while dismissed the notification. Try again later");

                goNext = false;
              }
            } else {
              addToast("error", "Error", "The targeted transponder cannot be identified. Try again later");

              goNext = false;
            }
          }
        } else if (currentQualification.qualification_type === "Update") {
          if (action === "update") {
            const techChannel = techChannels.find(tc => tc.id === currentQualification.data.id);

            if (techChannel) {
              try {
                const techChannelParam = {
                  service_name: currentQualification.data.service_name,
                  scrambled: currentQualification.data.scrambled
                };

                const { isSuccess } = await updateTechChannel(techChannel.id, techChannelParam);

                if (!isSuccess) {
                  throw new Error();
                }

                await deleteQualification(currentQualification.id);

                addToast("success", "Success", "Technical channel has been successfully updated");
              } catch (error) {
                addToast("error", "Error", "Error while updating the technical channel. Try again later");

                goNext = false;
              }
            } else {
              addToast("error", "Error", "The targeted technical channel cannot be identified. Try again later");

              goNext = false;
            }
          } else if (action === "updatepending" || action === "updatetransfer") {
            const techChannel = techChannels.find(tc => tc.id === currentQualification.data.id);

            if (techChannel) {
              try {
                // Delete associated services if needed
                // A service will be deleted if:
                // - It contains the current techChannel
                // - This techChannel is the only one to be present in this service
                const currentServices = services.filter(s => s.tech_channels.find(tc => tc.id === currentQualification.data.id) && s.tech_channels.length === 1);

                for (let i = 0; i < currentServices.length; i += 1) {
                  const service = currentServices[i];

                  await deleteService(service.id);

                  // If a service is deleted, check if the associated editorialChannel is always in use
                  // If not, it archives it if user check the checkbox
                  const editorialChannelId = service.channel_id;
                  const otherServices = services.find(s => s.id !== service.id && s.channel_id === editorialChannelId);

                  // If not any other service is using this editorialChannel
                  // and the user wants to delete it
                  // it archives it
                  if (!otherServices && deleteEditorial) {
                    await updateEditorialChannel(service.channel_id, { status: "archived" });
                  }
                }

                const techChannelParam = {
                  service_name: currentQualification.data.service_name,
                  scrambled: currentQualification.data.scrambled,
                  status: action === "updatepending" ? "pending" : "transfer"
                };

                const { isSuccess } = await updateTechChannel(techChannel.id, techChannelParam);

                if (!isSuccess) {
                  throw new Error();
                }

                await deleteQualification(currentQualification.id);

                addToast("success", "Success", "Technical channel has been successfully updated");
              } catch (error) {
                addToast("error", "Error", "Error while updating the technical channel. Try again later");

                goNext = false;
              }
            } else {
              addToast("error", "Error", "The targeted technical channel cannot be identified. Try again later");

              goNext = false;
            }
          } else if (action === "transferlist") {
            try {
              await updateQualification(currentQualification.id, { transferlist: true });

              addToast("success", "Success", "Notification was successfully put in the transfer list");
            } catch (error) {
              addToast("error", "Error", "Error while moving the notification. Try again later");

              goNext = false;
            }
          } else if (action === "discard") {
            const techChannel = techChannels.find(tc => tc.id === currentQualification.data.id);

            if (techChannel) {
              try {
                // Delete associated services if needed
                // A service will be deleted if:
                // - It contains the current techChannel
                // - This techChannel is the only one to be present in this service
                const currentServices = services.filter(s => s.tech_channels.find(tc => tc.id === currentQualification.data.id) && s.tech_channels.length === 1);

                for (let i = 0; i < currentServices.length; i += 1) {
                  const service = currentServices[i];

                  await deleteService(service.id);

                  // If a service is deleted, check if the associated editorialChannel is always in use
                  // If not, it archives it if user check the checkbox
                  const editorialChannelId = service.channel_id;
                  const otherServices = services.find(s => s.id !== service.id && s.channel_id === editorialChannelId);

                  // If not any other service is using this editorialChannel
                  // and the user wants to delete it
                  // it archives it
                  if (!otherServices && deleteEditorial) {
                    await updateEditorialChannel(service.channel_id, { status: "archived" });
                  }
                }

                const techChannelParam = {
                  service_name: currentQualification.data.service_name,
                  scrambled: currentQualification.data.scrambled,
                  status: "discarded"
                };

                const { isSuccess } = await updateTechChannel(techChannel.id, techChannelParam);

                if (!isSuccess) {
                  throw new Error();
                }

                await deleteQualification(currentQualification.id);

                addToast("success", "Success", "Technical channel has been successfully updated");
              } catch (error) {
                addToast("error", "Error", "Error while updating the technical channel. Try again later");

                goNext = false;
              }
            } else {
              addToast("error", "Error", "The targeted technical channel cannot be identified. Try again later");

              goNext = false;
            }
          } else if (action === "deletequalif") {
            // delete notification
            try {
              await deleteQualification(currentQualification.id);

              addToast("success", "Success", "Notification has been successfully dismissed");
            } catch (_) {
              addToast("error", "Error", "Error while dismissing the notification. Try again later");

              goNext = false;
            }
          }
        } else if (currentQualification.qualification_type === "Deletion") {
          if (action === "delete") {
            // delete tech_channels, and associated services
            try {
              // Delete associated services if needed
              // A service will be deleted if:
              // - It contains the current techChannel
              // - This techChannel is the only one to be present in this service
              const currentServices = services.filter(s => s.tech_channels.find(tc => tc.id === currentQualification.data.id) && s.tech_channels.length === 1);

              for (let i = 0; i < currentServices.length; i += 1) {
                const service = currentServices[i];

                await deleteService(service.id);

                // If a service is deleted, check if the associated editorialChannel is always in use
                // If not, it archives it if user check the checkbox
                const editorialChannelId = service.channel_id;
                const otherServices = services.find(s => s.id !== service.id && s.channel_id === editorialChannelId);

                // If not any other service is using this editorialChannel
                // and the user wants to delete it
                // it archives it
                if (!otherServices && deleteEditorial) {
                  await updateEditorialChannel(service.channel_id, { status: "archived" });
                }
              }

              // Delete tech_channels
              const { isSuccess } = await deleteTechChannel(currentQualification.data.id);

              if (!isSuccess) {
                throw new Error();
              }

              await deleteQualification(currentQualification.id);

              addToast("success", "Success", "Technical channel has been successfully deleted");
            } catch (error) {
              goNext = false;

              addToast("error", "Error", "Error while deleting the technical channel. Try again later");
            }
          } else if (action === "transferlist") {
            try {
              await updateQualification(currentQualification.id, { transferlist: true });

              addToast("success", "Success", "Notification was successfully put in the transfer list");
            } catch (error) {
              addToast("error", "Error", "Error while moving the notification. Try again later");

              goNext = false;
            }
          } else if (action === "deletequalif") {
            // delete notification
            try {
              await deleteQualification(currentQualification.id);

              addToast("success", "Success", "Notification has been successfully dismissed");
            } catch (_) {
              addToast("error", "Error", "Error while dismissing the notification. Try again later");

              goNext = false;
            }
          }
        }

      // TRANSPONDER
      } else if (currentQualification.data_type === "Transponder") {
        if (action === "deletequalif") {
          // delete notification
          try {
            await deleteQualification(currentQualification.id);

            addToast("success", "Success", "Notification has been successfully dismissed");
          } catch (_) {
            addToast("error", "Error", "Error while dismissing the notification. Try again later");

            goNext = false;
          }
        } else if (action === "discardqualif") {
          try {
            await updateQualification(currentQualification.id, { discarded: true });

            addToast("success", "Success", "Notification was successfully archived");
          } catch (error) {
            addToast("error", "Error", "Error while archived the notification. Try again later");

            goNext = false;
          }
        }
        if (currentQualification.qualification_type === "Update") {
          if (action === "update") {
            const transponder = transponders.find(t => t.id === currentQualification.data.transponder_id);

            if (transponder) {
              try {
                const { isSuccess } = await updateTransponder(transponder.id, currentQualification.data);

                if (!isSuccess) {
                  throw new Error();
                }

                await deleteQualification(currentQualification.id);

                addToast("success", "Success", "Transponder has been successfully updated");
              } catch (error) {
                addToast("error", "Error", "Error while updating the transponder. Try again later");

                goNext = false;
              }
            } else {
              addToast("error", "Error", "The targeted transponder cannot be identified. Try again later");

              goNext = false;
            }
          }
        } else if (currentQualification.qualification_type === "Deletion") {
          if (action === "delete") {
            const transponder = transponders.find(t => t.id === currentQualification.data.transponder_id);

            if (transponder) {
              try {
                const { isSuccess } = await await deleteTransponder(transponder.id);

                if (!isSuccess) {
                  throw new Error();
                }

                await deleteQualification(currentQualification.id);

                addToast("success", "Success", "Transponder has been successfully deleted");
              } catch (error) {
                addToast("error", "Error", "Error while deleting the transponder. Try again later");

                goNext = false;
              }
            } else {
              addToast("error", "Error", "The targeted transponder cannot be identified. Try again later");

              goNext = false;
            }
          }
        }
      }
    }

    // Go to the next qualification
    // If it is the last qualification,
    // nextStep() will close the modal
    if (goNext) {
      this.nextStep();
    }
  }

  /* Modal */

  openLineupServiceModal() {
    const { predefinedTechChannels } = this.state;

    /* Predefined techChannels */
    if (!predefinedTechChannels || predefinedTechChannels.length === 0) {
      // eslint-disable-next-line no-console
      console.error("Error while stored the created techChannels (state.predefinedTechChannels === [])");

      return;
    }

    this.setState({
      modalOpened: true
    });
  }

  async closeLineupServiceModal(_, isSuccess) {
    const { step } = this.state;
    const { currentQualificationsIds, deleteQualification } = this.props;

    this.setState({
      modalOpened: false
    });

    const qualificationId = currentQualificationsIds[step];

    if (!isSuccess) {
      const message = "You have cancel the creation of a service in a lineup. However, the technical channel has successfully been created. If you want to associate the technical channel to a lineup later, go to the lineup page";

      window.alert(message);
    }

    this.nextStep();

    await deleteQualification(qualificationId);
  }

  closeModal(done) {
    const { closeModal } = this.props;

    closeModal(done);
  }

  render() {
    const {
      step,
      maxStep,
    } = this.state;

    const {
      currentQualificationsIds,

      qualifications,
      transponders,
      techChannels,
      orbitalPositions,

      modalOpened,
      isLoading
    } = this.props;

    const pitch = maxStep > 0 ? 100 / maxStep : 100;
    let currentQualificationId = 0;
    let currentQualification = null;
    let canBeProcessed = false;
    let transponder = null;
    let orbitalPosition = null;

    if (currentQualificationsIds.length > 0) {
      currentQualificationId = currentQualificationsIds[step];
      currentQualification = qualifications.find(q => q.id === currentQualificationId);

      if (currentQualification
        && (currentQualification.qualification_type === "Creation"
        || currentQualification.qualification_type === "Update")
        && currentQualification.orbital_position_id !== null) {
        transponder = transponders.find(t => t.id === currentQualification.data.transponder_id);
        orbitalPosition = orbitalPositions.find(op => op.id === currentQualification.orbital_position_id);

        if (transponder && orbitalPosition) {
          canBeProcessed = true;
        }
      }
    }

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={modalOpened}
        onClose={() => this.closeModal()}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3" component="span" >Process notification(s)</Typography>
          <CloseIcon
            sx={{ color: "text.grey" }}
            onClick={() => this.closeModal()}
          />
        </DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          {isLoading && <FullLoader />}
          {!isLoading && currentQualificationId > 0 && !!currentQualification
            && <>
              <Box
                sx={{ textAlign: "center", marginBottom: 2 }}
              >{`Notification ${step + 1} / ${maxStep + 1}`}</Box>
              <LinearProgress
                variant="determinate"
                value={Math.min(step * pitch, 100)}
                sx={{ borderRadius: "10px" }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Infos
                  notifData={currentQualification}
                  transponders={transponders}
                  techChannels={techChannels}
                  canBeProcessed={canBeProcessed}
                  processQualification={(action, deleteEditorial) => this.processQualification(action, deleteEditorial)}
                ></Infos>
              </Box>
            </>
          }
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            onClick={() => this.closeModal()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            sx={{
              margin: 1
            }}
            onClick={() => this.nextStep()}
          >
            Ignore this notification
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

QualificationModal.propTypes = {
  currentQualificationsIds: PropTypes.arrayOf(PropTypes.number).isRequired,

  modalOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,

  qualifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orbitalPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transponders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  techChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  listOrbitalPositionsLineupsFromBeamId: PropTypes.func.isRequired,

  indexLineups: PropTypes.func.isRequired,
  updateQualification: PropTypes.func.isRequired,
  deleteQualification: PropTypes.func.isRequired,
  indexEditorialChannels: PropTypes.func.isRequired,
  updateEditorialChannel: PropTypes.func.isRequired,
  indexServices: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  createTechChannel: PropTypes.func.isRequired,
  updateTechChannel: PropTypes.func.isRequired,
  deleteTechChannel: PropTypes.func.isRequired,
  updateTransponder: PropTypes.func.isRequired,
  deleteTransponder: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,

  isLoading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  updateQualification: updateQualificationAction,
  deleteQualification: deleteQualificationAction,
  indexLineups: indexLineupsAction,
  indexEditorialChannels: indexEditorialChannelsAction,
  updateEditorialChannel: updateEditorialChannelAction,
  indexServices: indexServicesAction,
  deleteService: deleteServiceAction,
  createTechChannel: createTechChannelAction,
  updateTechChannel: updateTechChannelAction,
  deleteTechChannel: deleteTechChannelAction,
  updateTransponder: updateTransponderAction,
  deleteTransponder: deleteTransponderAction,
  addToast: addToastAction
};

function mapStateToProps(state) {
  return {
    qualifications: listQualificationsSelector(state),
    orbitalPositions: listOrbitalPositionsSelector(state),
    services: listServicesSelector(state),
    transponders: listTranspondersSelector(state),
    techChannels: listTechChannelsSelector(state),
    listOrbitalPositionsLineupsFromBeamId: beamId => listOrbitalPositionsLineupsFromBeamIdSelector(state, beamId),
    isLoading: isLoadingSelector(state, updateQualificationAction.toString())
                || isLoadingSelector(state, deleteQualificationAction.toString())
                || isLoadingSelector(state, indexEditorialChannelsAction.toString())
                || isLoadingSelector(state, updateEditorialChannelAction.toString())
                || isLoadingSelector(state, indexServicesAction.toString())
                || isLoadingSelector(state, deleteServiceAction.toString())
                || isLoadingSelector(state, createTechChannelAction.toString())
                || isLoadingSelector(state, updateTechChannelAction.toString())
                || isLoadingSelector(state, deleteTechChannelAction.toString())
                || isLoadingSelector(state, updateTransponderAction.toString())
                || isLoadingSelector(state, deleteTransponderAction.toString())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QualificationModal);
