import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  faCheck,
  faHourglassHalf,
  faBan
} from "@fortawesome/free-solid-svg-icons";
import { qualificationServiceName } from "../../helpers/utils";

const attributesStatus = status => {
  switch (status) {
    case "discarded":
      return <FontAwesomeIcon className="fs20 red-icon" icon={faBan} />;
    case "pending":
      return <FontAwesomeIcon className="fs20 orange-icon" icon={faHourglassHalf} />;
    case "active":
      return <FontAwesomeIcon className="fs20 green-icon" icon={faCheck} />;
    default:
      return "";
  }
};

const polarizationName = pol => {
  switch (pol) {
    case "lh":
      return "linear - horizontal";
    case "lv":
      return "linear - vertical";
    case "cl":
      return "circular - left";
    case "cr":
      return "circular - right";
    default:
      return "null";
  }
};

const QualificationAttributes = ({ qualif, techChannels, transponders }) => {
  if (qualif.data_type === "TechChannel") {
    const qualifType = qualif.qualification_type;
    const transponder = transponders.find(t => t.id === qualif.data.transponder_id);
    const techChannel = techChannels.find(tc => tc.id === qualif.data.id);
    let currentTechChannelStatus = "";
    let serviceName = "";
    let sid = "";
    let scrambled = "";

    if (qualifType === "Creation") {
      serviceName = qualif.data.service_name || qualificationServiceName(transponder, qualif.data);
      sid = qualif.data.sid;
      scrambled = qualif.data.scrambled;
    } else if (qualifType === "Update" && techChannel) {
      currentTechChannelStatus =
        <>
          <span className="bold">Current technical channel status</span>
          {`: ${techChannel.status}`}
          <br />
        </>;

      if (qualif.data.service_name && qualif.data.service_name !== techChannel.service_name) {
        serviceName = `${techChannel.service_name} -> ${qualif.data.service_name}`;
      } else {
        serviceName = techChannel.service_name;
      }

      if (qualif.data.sid && qualif.data.sid !== techChannel.sid) {
        sid = `${techChannel.sid} -> ${qualif.data.sid}`;
      } else {
        sid = techChannel.sid;
      }

      if ([true, false].indexOf(qualif.data.scrambled) !== -1 && qualif.data.scrambled !== techChannel.scrambled) {
        scrambled = `${techChannel.scrambled} -> ${qualif.data.scrambled}`;
      } else {
        scrambled = techChannel.scrambled;
      }
    } else if (qualifType === "Deletion" && techChannel) {
      currentTechChannelStatus =
        <>
          <span className="bold">Current technical channel status</span>
          {`: ${techChannel.status}`}
          <br />
        </>;

      serviceName = techChannel.service_name;
      sid = techChannel.sid;
      scrambled = techChannel.scrambled;
    }

    return (
      <Typography py={1} variant="body3">
        {currentTechChannelStatus}
        <span className="bold">Service_name</span>
        {`: ${serviceName}`}
        <br />
        <span className="bold">Transponder</span>
        {`: ${transponder ? `${transponder.name}` : `${qualif.data.transponder_id} (Transponder cannot be identified)`}`}
        <br />
        <span className="bold">SID</span>
        {`: ${sid}`}
        <br />
        <span className="bold">Scrambled</span>
        {`: ${scrambled}`}
      </Typography>
    );
  }

  if (qualif.data_type === "Transponder") {
    const qualifType = qualif.qualification_type;
    const transponder = transponders.find(t => t.id === qualif.data.transponder_id);

    if (qualifType === "Update" && transponder) {
      return (
        <Typography py={1} variant="body3">
          <span className="bold">Transponder</span>
          {`: ${transponder.name}`}
          <br />
          {!!qualif.data.frequency
            && <>
              <span className="bold">Frequency</span>
              {`: ${transponder.frequency || "null"} -> ${qualif.data.frequency}`}
              <br />
            </>
          }
          {!!qualif.data.modulation_type
            && <>
              <span className="bold">Modulation Type</span>
              {`: ${transponder.modulation_type || "null"} -> ${qualif.data.modulation_type}`}
              <br />
            </>
          }
          {!!qualif.data.modulation_system
            && <>
              <span className="bold">Modulation System</span>
              {`: ${transponder.modulation_system || "null"} -> ${qualif.data.modulation_system}`}
              <br />
            </>
          }
          {!!qualif.data.polarization
            && <>
              <span className="bold">Polarization</span>
              {`: ${polarizationName(transponder.polarization) || "null"} -> ${polarizationName(qualif.data.polarization)}`}
              <br />
            </>
          }
          {!!qualif.data.symbol_rate
            && <>
              <span className="bold">Symbol Rate</span>
              {`: ${transponder.symbol_rate || "null"} -> ${qualif.data.symbol_rate}`}
              <br />
            </>
          }
          {!!qualif.data.fec_inner
            && <>
              <span className="bold">FEC Inner</span>
              {`: ${transponder.fec_inner || "null"} -> ${qualif.data.fec_inner}`}
              <br />
            </>
          }
          {!!qualif.data.roll_off
            && <>
              <span className="bold">Roll Off</span>
              {`: ${transponder.roll_off || "null"} -> ${qualif.data.roll_off}`}
              <br />
            </>
          }
        </Typography>
      );
    }

    if (qualifType === "Deletion" && transponder) {
      const currentTechChannels = techChannels.filter(tc => tc.transponder_id === transponder.id);
      const activeTCs = currentTechChannels.filter(tc => tc.status === "active");
      const pendingTCs = currentTechChannels.filter(tc => tc.status === "pending");
      const discardedTCs = currentTechChannels.filter(tc => tc.status === "discarded");

      return (
        <Typography py={1} variant="body3">
          <span className="bold">Transponder</span>
          {`: ${transponder.name}`}
          <br />
          {activeTCs.length > 0
            && <>
              <span className="bold">
                {attributesStatus("active")}
                {" Associated active technical channels"}
              </span>
              {`: ${activeTCs.map(tc => tc.service_name).join(", ")}`}
              <br />
            </>
          }
          {pendingTCs.length > 0
            && <>
              <span className="bold">
                {attributesStatus("pending")}
                {" Associated pending technical channels"}
              </span>
              {`: ${pendingTCs.map(tc => tc.service_name).join(", ")}`}
              <br />
            </>
          }
          {discardedTCs.length > 0
            && <>
              <span className="bold">
                {attributesStatus("discarded")}
                {" Associated discarded technical channels"}
              </span>
              {`: ${discardedTCs.map(tc => tc.service_name).join(", ")}`}
              <br />
            </>
          }
          {currentTechChannels.length === 0
            && <>
              <span className="bold">Associated technical channels</span>
: none
            </>
          }
        </Typography>
      );
    }
  }

  return JSON.stringify(qualif.data);
};

QualificationAttributes.propTypes = {
  qualif: PropTypes.shape({
    data_type: PropTypes.string.isRequired,
    qualification_type: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number,
      transponder_id: PropTypes.number,
      frequency: PropTypes.number,
      modulation_type: PropTypes.string,
      modulation_system: PropTypes.string,
      polarization: PropTypes.string,
      symbol_rate: PropTypes.number,
      fec_inner: PropTypes.string,
      roll_off: PropTypes.number,
      service_name: PropTypes.string,
      sid: PropTypes.number,
      scrambled: PropTypes.bool
    }).isRequired
  }).isRequired,
  techChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transponders: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default QualificationAttributes;
