import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  Stack,
  Typography,
  Button,
} from "@mui/material";

import {
  indexQualifications as indexQualificationsAction,
  updateQualification as updateQualificationAction
} from "../../actions/qualifications";

import { listQualifications as listQualificationsSelector } from "../../selectors/qualifications";
import {
  listTechChannels as listTechChannelsSelector
} from "../../selectors/tech_channels";
import { listTransponders as listTranspondersSelector } from "../../selectors/transponders";
import { listOrbitalPositions as listOrbitalPositionsSelector } from "../../selectors/orbital_positions";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import FullLoader from "../../components/FullLoader";
import DataTable from "../../components/DataTable";
import QualificationAttributes from "../../components/QualificationAttributes";

import {
  capitalize,
  stringifyQualificationType,
} from "../../helpers/utils";

function Archives({
  isQualificationsLoading,
  techChannels,
  qualifications,
  orbitalPositions,
  transponders,
  updateQualification,
}) {
  const [currentQualificationsIds, setSelectedQualifications] = useState([]);
  const [toReset] = useState(false);

  async function updateQualifications(selectedQualifications, data) {
    if (selectedQualifications.length > 0) {
      if (!window.confirm(`Are you sure to update these notifications: ${selectedQualifications.join(", ")} ?`)) {
        return;
      }
    }

    let processed = 0;
    for (let i = 0; i < selectedQualifications.length; i += 1) {
      const currentQualification = qualifications.find(q => q.id === selectedQualifications[i]);

      try {
        await updateQualification(currentQualification.id, data);

        processed += 1;
      } catch (_) {}
    }
  }

  return (
    <div id="qualifications" className="page-content">
      { isQualificationsLoading
        ? <FullLoader />
        : <>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
            <Link to="/notifications">
              <Typography variant="body3" color="text.primary" sx={{ textDecoration: "underline" }}>&#x2190; Retour aux notifications</Typography>
            </Link>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="flex-start" sx={{ mb: 1 }}>
            <Button disabled={!currentQualificationsIds.length} variant="outlined" onClick={() => updateQualifications(currentQualificationsIds, { discarded: false })}>Restore selected notifications</Button>
          </Stack>
          <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
            <DataTable
              disableRowClick
              pagination
              checkboxSelection
              cols={[
                {
                  headerName: "TL?",
                  field: "transferlist",
                  sortable: true,
                  renderCell: params => params.value ? <FontAwesomeIcon className="orange-icon" icon={faExclamationCircle} /> : "",
                }, {
                  headerName: "Id",
                  field: "id",
                  sortable: true,
                }, {
                  headerName: "Orbital position",
                  field: "orbital_position_id",
                  sortable: true,
                  renderCell: params => params.value ? orbitalPositions.find(op => op.id === params.value).name : "",
                }, {
                  headerName: "Object",
                  field: "data_type",
                  sortable: true,
                  renderCell: params => params.value ? capitalize(stringifyQualificationType(params.value)) : "",
                }, {
                  headerName: "Action Type",
                  field: "qualification_type",
                  sortable: true,
                }, {
                  headerName: "Object Attributes",
                  field: "attributes",
                  flex: 1,
                  renderCell: ({ row }) => <QualificationAttributes qualif={row} techChannels={techChannels} transponders={transponders} />
                }, {
                  headerName: "Date",
                  field: "updated_at",
                  sortable: true,
                }
              ]}
              onRowSelectionModelChange={setSelectedQualifications}
              data={qualifications}
              toReset={toReset}
              selectionToReset={currentQualificationsIds}
            />
          </div>
        </>
      }
    </div>
  );
}

Archives.propTypes = {
  isQualificationsLoading: PropTypes.bool.isRequired,
  techChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  qualifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orbitalPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transponders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateQualification: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    techChannels: listTechChannelsSelector(state),
    qualifications: listQualificationsSelector(state, { discarded: true }),
    isQualificationsLoading: isLoadingSelector(state, indexQualificationsAction.toString())
                              || isLoadingSelector(state, updateQualificationAction.toString()),
    orbitalPositions: listOrbitalPositionsSelector(state),
    transponders: listTranspondersSelector(state)
  };
}

const mapDispatchToProps = {
  updateQualification: updateQualificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Archives);
