import {
  faMinusCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

import Dashboard from "../containers/Dashboard";
import EditorialChannels from "../containers/EditorialChannels";
import Lineups from "../containers/Lineups";
import SpaceObjects from "../containers/SpaceObjects";
import GlobalObjects from "../containers/GlobalObjects";
import TechnicalChannels from "../containers/TechnicalChannels";
import Reports from "../containers/Reports";
import Drivers from "../containers/Drivers";
import Qualifications from "../containers/Qualifications";
import Changelog from "../containers/Changelog";
import Archives from "../containers/Archives";

import PageNotFound from "../containers/PageNotFound";

import { ReactComponent as IcoDashboard } from "../assets/icons/dashboard.svg";
import { ReactComponent as IcoDrivers } from "../assets/icons/drivers.svg";
import { ReactComponent as IcoEditorialChan } from "../assets/icons/editorial_chan.svg";
import { ReactComponent as IcoGlobalObjects } from "../assets/icons/global_objects.svg";
import { ReactComponent as IcoLineups } from "../assets/icons/lineups.svg";
import { ReactComponent as IcoNotifications } from "../assets/icons/notifications.svg";
import { ReactComponent as IcoReports } from "../assets/icons/reports.svg";
import { ReactComponent as IcoSpaceObjects } from "../assets/icons/space_objects.svg";
import { ReactComponent as IcoTechnicalChan } from "../assets/icons/technical_chan.svg";

export const routes = [

  // Private Routes

  {
    route: "/",
    routePath: "/",
    slug: "dashboard",
    title: "Dashboard",
    icon: IcoDashboard,
    private: true,
    showInMenu: true,
    component: Dashboard
  },
  {
    route: "/editorial_channels",
    routePath: "/editorial_channels",
    slug: "editorial_channels",
    title: "Editorial channels",
    icon: IcoEditorialChan,
    private: true,
    showInMenu: true,
    component: EditorialChannels
  },
  {
    route: "/lineups",
    routePath: "/lineups",
    slug: "lineups",
    title: "Lineups",
    icon: IcoLineups,
    private: true,
    showInMenu: true,
    component: Lineups
  },
  {
    route: "/technical_channels/*",
    routePath: "/technical_channels",
    slug: "technical_channels",
    title: "Technical channels",
    icon: IcoTechnicalChan,
    private: true,
    showInMenu: true,
    component: TechnicalChannels,
    exact: false
  },
  {
    route: "/space_objects/*",
    routePath: "/space_objects",
    slug: "space_objects",
    title: "Space Objects",
    icon: IcoSpaceObjects,
    private: true,
    showInMenu: true,
    component: SpaceObjects,
    exact: false
  },
  {
    route: "/global_objects/*",
    routePath: "/global_objects",
    slug: "global_objects",
    title: "Global Objects",
    icon: IcoGlobalObjects,
    private: true,
    showInMenu: true,
    component: GlobalObjects,
    exact: false
  },
  {
    route: "/drivers/*",
    routePath: "/drivers",
    slug: "drivers",
    title: "Drivers",
    icon: IcoDrivers,
    private: true,
    showInMenu: true,
    component: Drivers,
    exact: false
  },
  {
    route: "/reports/*",
    routePath: "/reports",
    slug: "reports",
    title: "Reports",
    icon: IcoReports,
    private: true,
    showInMenu: true,
    component: Reports,
    exact: false
  },
  {
    route: "/notifications/*",
    routePath: "/notifications",
    slug: "notifications",
    title: "Notifications",
    icon: IcoNotifications,
    private: true,
    showInMenu: true,
    component: Qualifications,
    exact: false
  },
  {
    route: "/archives/*",
    routePath: "/archives",
    slug: "archives",
    title: "Archived notifications",
    icon: IcoNotifications,
    private: true,
    showInMenu: false,
    component: Archives,
    exact: false
  },
  {
    route: "/changelog/*",
    routePath: "/changelog",
    slug: "changelog",
    title: "Changelog",
    icon: faBook,
    private: true,
    showInMenu: false,
    component: Changelog,
    exact: false
  },

  // Public Routes

  {
    route: "/404",
    routePath: "/404",
    title: "Page not found",
    icon: faMinusCircle,
    component: PageNotFound
  }
];
