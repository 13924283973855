import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import FullLoader from "../../components/FullLoader";

import { indexBeams as indexBeamsAction } from "../../actions/beams";
import { indexBeamsLineups as indexBeamsLineupsAction } from "../../actions/beams_lineups";
import { indexEditorialChannels as indexEditorialChannelsAction } from "../../actions/editorial_channels";
import { indexLineups as indexLineupsAction } from "../../actions/lineups";
import { indexLineupsServices as indexLineupsServicesAction } from "../../actions/lineups_services";
import { indexOrbitalPositions as indexOrbitalPositionsAction } from "../../actions/orbital_positions";
import { indexChannelTiers as indexChannelTiersAction } from "../../actions/channel_tiers";
import { indexOttBackends as indexOttBackendsAction } from "../../actions/ott_backends";
import { indexImportances as indexImportancesAction } from "../../actions/importances";
import { indexServiceGenres as indexServiceGenresAction } from "../../actions/service_genres";
import { indexContentGenres as indexContentGenresAction } from "../../actions/content_genres";
import { indexServiceTypes as indexServiceTypesAction } from "../../actions/service_types";
import { indexTechChannels as indexTechChannelsAction } from "../../actions/tech_channels";
import { indexTransponders as indexTranspondersAction } from "../../actions/transponders";
import { indexQualifications as indexQualificationsAction } from "../../actions/qualifications";
import { indexProviders as indexProvidersAction } from "../../actions/providers";
import { indexProvidersWordings as indexProvidersWordingsAction } from "../../actions/providers_wordings";

import { listOrbitalPositions as listOrbitalPositionsSelector } from "../../selectors/orbital_positions";
import { listBeams as listBeamsSelector } from "../../selectors/beams";
import { listBeamsLineups as listBeamsLineupsSelector } from "../../selectors/beams_lineups";
import { listEditorialChannels as listEditorialChannelsSelector } from "../../selectors/editorial_channels";
import { listLineups as listLineupsSelector } from "../../selectors/lineups";
import { listLineupsServices as listLineupsServicesSelector } from "../../selectors/lineups_services";
import { listChannelTiers as listChannelTiersSelector } from "../../selectors/channel_tiers";
import { listOttBackends as listOttBackendsSelector } from "../../selectors/ott_backends";
import { listImportances as listImportancesSelector } from "../../selectors/importances";
import { listServiceGenres as listServiceGenresSelector } from "../../selectors/service_genres";
import { listContentGenres as listContentGenresSelector } from "../../selectors/content_genres";
import { listServiceTypes as listServiceTypesSelector } from "../../selectors/service_types";
import { listTechChannels as listTechChannelsSelector } from "../../selectors/tech_channels";
import { listTransponders as listTranspondersSelector } from "../../selectors/transponders";
import { listQualifications as listQualificationsSelector } from "../../selectors/qualifications";
import { listProviders as listProvidersSelector } from "../../selectors/providers";
import { listProvidersWordings as listProvidersWordingsSelector } from "../../selectors/providers_wordings";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

class GlobalResourceLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false
    };
  }

  componentDidMount() {
    const {
      orbitalPositions,
      beams,
      editorialChannels,
      transponders,
      beamsLineups,
      lineups,
      lineupsServices,
      channelTiers,
      ottBackends,
      serviceGenres,
      contentGenres,
      serviceTypes,
      techChannels,
      qualifications,
      providers,
      providersWordings,
      importances,

      indexOrbitalPositions,
      indexBeams,
      indexEditorialChannels,
      indexTransponders,
      indexBeamsLineups,
      indexLineups,
      indexLineupsServices,
      indexChannelTiers,
      indexOttBackends,
      indexServiceGenres,
      indexContentGenres,
      indexServiceTypes,
      indexTechChannels,
      indexQualifications,
      indexProviders,
      indexProvidersWordings,
      indexImportances
    } = this.props;

    if (orbitalPositions.length === 0) {
      indexOrbitalPositions({}, true);
    }

    if (beams.length === 0) {
      indexBeams({}, true);
    }

    if (editorialChannels.length === 0) {
      indexEditorialChannels({}, true);
    }

    if (beams.length === 0) {
      indexBeams({}, true);
    }

    if (transponders.length === 0) {
      indexTransponders({}, true);
    }

    if (beamsLineups.length === 0) {
      indexBeamsLineups({}, true);
    }

    if (lineups.length === 0) {
      indexLineups({}, true);
    }

    if (lineupsServices.length === 0) {
      indexLineupsServices({}, true);
    }

    if (channelTiers.length === 0) {
      indexChannelTiers({}, true);
    }

    if (ottBackends.length === 0) {
      indexOttBackends({}, true);
    }

    if (serviceGenres.length === 0) {
      indexServiceGenres({}, true);
    }

    if (contentGenres.length === 0) {
      indexContentGenres({}, true);
    }

    if (serviceTypes.length === 0) {
      indexServiceTypes({}, true);
    }

    if (techChannels.length === 0) {
      indexTechChannels({}, true);
    }

    if (qualifications.length === 0) {
      indexQualifications({}, true);
    }

    if (providers.length === 0) {
      indexProviders({}, true);
    }

    if (providersWordings.length === 0) {
      indexProvidersWordings({}, true);
    }

    if (importances.length === 0) {
      indexImportances({}, true);
    }

    this.setState({ initialized: true });
  }

  render() {
    const {
      children,
      isLoadingOrbitalPositions,
      isLoadingBeams,
      isLoadingBeamsLineups,
      isLoadingEditorialChannels,
      isLoadingLineups,
      isLoadingLineupsServices,
      isLoadingChannelTiers,
      isLoadingOttBackends,
      isLoadingServiceGenres,
      isLoadingContentGenres,
      isLoadingServiceTypes,
      isLoadingTechChannels,
      isLoadingTransponders,
      isLoadingQualifications,
      isLoadingProviders,
      isLoadingProvidersWordings,
      isLoadingImportances
    } = this.props;

    const { initialized } = this.state;

    const isLoadingResource = !initialized && (
      isLoadingOrbitalPositions
      || isLoadingBeams
      || isLoadingBeamsLineups
      || isLoadingEditorialChannels
      || isLoadingLineups
      || isLoadingLineupsServices
      || isLoadingChannelTiers
      || isLoadingOttBackends
      || isLoadingServiceGenres
      || isLoadingContentGenres
      || isLoadingServiceTypes
      || isLoadingTechChannels
      || isLoadingTransponders
      || isLoadingQualifications
      || isLoadingProviders
      || isLoadingProvidersWordings
      || isLoadingImportances
    );

    const renderingComponent = isLoadingResource ? <FullLoader /> : children;

    return (
      <>
        {renderingComponent}
      </>
    );
  }
}

GlobalResourceLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,

  orbitalPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
  beams: PropTypes.arrayOf(PropTypes.object).isRequired,
  beamsLineups: PropTypes.arrayOf(PropTypes.object).isRequired,
  editorialChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineups: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineupsServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  channelTiers: PropTypes.arrayOf(PropTypes.object).isRequired,
  ottBackends: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceGenres: PropTypes.arrayOf(PropTypes.object).isRequired,
  contentGenres: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  techChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
  transponders: PropTypes.arrayOf(PropTypes.object).isRequired,
  qualifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  providersWordings: PropTypes.arrayOf(PropTypes.object).isRequired,
  importances: PropTypes.arrayOf(PropTypes.object).isRequired,

  isLoadingOrbitalPositions: PropTypes.bool.isRequired,
  isLoadingBeams: PropTypes.bool.isRequired,
  isLoadingBeamsLineups: PropTypes.bool.isRequired,
  isLoadingEditorialChannels: PropTypes.bool.isRequired,
  isLoadingLineups: PropTypes.bool.isRequired,
  isLoadingLineupsServices: PropTypes.bool.isRequired,
  isLoadingChannelTiers: PropTypes.bool.isRequired,
  isLoadingOttBackends: PropTypes.bool.isRequired,
  isLoadingServiceGenres: PropTypes.bool.isRequired,
  isLoadingContentGenres: PropTypes.bool.isRequired,
  isLoadingServiceTypes: PropTypes.bool.isRequired,
  isLoadingTechChannels: PropTypes.bool.isRequired,
  isLoadingTransponders: PropTypes.bool.isRequired,
  isLoadingQualifications: PropTypes.bool.isRequired,
  isLoadingProviders: PropTypes.bool.isRequired,
  isLoadingProvidersWordings: PropTypes.bool.isRequired,
  isLoadingImportances: PropTypes.bool.isRequired,

  indexOrbitalPositions: PropTypes.func.isRequired,
  indexBeams: PropTypes.func.isRequired,
  indexBeamsLineups: PropTypes.func.isRequired,
  indexEditorialChannels: PropTypes.func.isRequired,
  indexLineups: PropTypes.func.isRequired,
  indexLineupsServices: PropTypes.func.isRequired,
  indexChannelTiers: PropTypes.func.isRequired,
  indexOttBackends: PropTypes.func.isRequired,
  indexServiceGenres: PropTypes.func.isRequired,
  indexContentGenres: PropTypes.func.isRequired,
  indexServiceTypes: PropTypes.func.isRequired,
  indexTechChannels: PropTypes.func.isRequired,
  indexTransponders: PropTypes.func.isRequired,
  indexQualifications: PropTypes.func.isRequired,
  indexProviders: PropTypes.func.isRequired,
  indexProvidersWordings: PropTypes.func.isRequired,
  indexImportances: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    orbitalPositions: listOrbitalPositionsSelector(state),
    beams: listBeamsSelector(state),
    beamsLineups: listBeamsLineupsSelector(state),
    lineups: listLineupsSelector(state),
    lineupsServices: listLineupsServicesSelector(state),
    editorialChannels: listEditorialChannelsSelector(state),
    channelTiers: listChannelTiersSelector(state),
    ottBackends: listOttBackendsSelector(state),
    serviceGenres: listServiceGenresSelector(state),
    contentGenres: listContentGenresSelector(state),
    serviceTypes: listServiceTypesSelector(state),
    techChannels: listTechChannelsSelector(state),
    transponders: listTranspondersSelector(state),
    qualifications: listQualificationsSelector(state),
    providers: listProvidersSelector(state),
    providersWordings: listProvidersWordingsSelector(state),
    importances: listImportancesSelector(state),

    isLoadingOrbitalPositions: isLoadingSelector(state, indexOrbitalPositionsAction.toString()),
    isLoadingBeams: isLoadingSelector(state, indexBeamsAction.toString()),
    isLoadingBeamsLineups: isLoadingSelector(state, indexBeamsLineupsAction.toString()),
    isLoadingLineups: isLoadingSelector(state, indexLineupsAction.toString()),
    isLoadingLineupsServices: isLoadingSelector(state, indexLineupsServicesAction.toString()),
    isLoadingEditorialChannels: isLoadingSelector(state, indexEditorialChannelsAction.toString()),
    isLoadingChannelTiers: isLoadingSelector(state, indexChannelTiersAction.toString()),
    isLoadingOttBackends: isLoadingSelector(state, indexOttBackendsAction.toString()),
    isLoadingServiceGenres: isLoadingSelector(state, indexServiceGenresAction.toString()),
    isLoadingContentGenres: isLoadingSelector(state, indexContentGenresAction.toString()),
    isLoadingServiceTypes: isLoadingSelector(state, indexServiceTypesAction.toString()),
    isLoadingTechChannels: isLoadingSelector(state, indexTechChannelsAction.toString()),
    isLoadingTransponders: isLoadingSelector(state, indexTranspondersAction.toString()),
    isLoadingQualifications: isLoadingSelector(state, indexQualificationsAction.toString()),
    isLoadingProviders: isLoadingSelector(state, indexProvidersAction.toString()),
    isLoadingProvidersWordings: isLoadingSelector(state, indexProvidersWordingsAction.toString()),
    isLoadingImportances: isLoadingSelector(state, indexImportancesAction.toString())
  };
}

const mapDispatchToProps = {
  indexBeams: indexBeamsAction,
  indexBeamsLineups: indexBeamsLineupsAction,
  indexLineups: indexLineupsAction,
  indexLineupsServices: indexLineupsServicesAction,
  indexEditorialChannels: indexEditorialChannelsAction,
  indexOrbitalPositions: indexOrbitalPositionsAction,
  indexChannelTiers: indexChannelTiersAction,
  indexOttBackends: indexOttBackendsAction,
  indexServiceTypes: indexServiceTypesAction,
  indexServiceGenres: indexServiceGenresAction,
  indexContentGenres: indexContentGenresAction,
  indexTechChannels: indexTechChannelsAction,
  indexTransponders: indexTranspondersAction,
  indexQualifications: indexQualificationsAction,
  indexProviders: indexProvidersAction,
  indexProvidersWordings: indexProvidersWordingsAction,
  indexImportances: indexImportancesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalResourceLoader);
